// :root {
//     // Light theme variables
//     --main-font-color-light: black;
//     --link-color-light: #5b7aa8;
//     --link-color-active-light: #5b7aa8;
//     --menu-link-color-light: black;
//     --menu-link-color-active-light: black;
//     --menu-background-color-light: #FBFBFB;
//     --body-background-color-light: white;
//     --separator-color-light: rgb(235, 235, 235);

//     // Dark theme variables 
//     --main-font-color-dark: #E6EDF3;
//     --link-color-dark: #E6EDF3;
//     --link-color-active-dark: #E6EDF3;
//     --menu-link-color-dark: #E6EDF3;
//     --menu-link-color-active-dark: #E6EDF3;
//     --menu-background-color-dark: #0D1117; 
//     --body-background-color-dark: #010409;
//     --separator-color-dark: rgb(70, 70, 70);
// }

html[data-theme="light"] {
    --main-font-color: black;
    --link-color: #5b7aa8;
    --link-color-active: #5b7aa8;
    --menu-link-color: black;
    --menu-link-color-active: black;
    --menu-background-color: #FBFBFB;
    --body-background-color: white;
    --separator-color: rgb(235, 235, 235);
    --link-inactive-color: white;
}

html[data-theme="dark"] {
    --main-font-color: #E6EDF3;
    --link-color: #E6EDF3;
    --link-color-active: #E6EDF3;
    --menu-link-color: #E6EDF3;
    --menu-link-color-active: #E6EDF3;
    --menu-background-color: #0D1117;
    --body-background-color: #010409;
    --separator-color: rgb(70, 70, 70);
    --link-inactive-color: rgb(87, 87, 87);
}

$main-font-color: var(--main-font-color);
$link-color: var(--link-color);
$link-active-color: var(--link-active-color);
$menu-link-color: var(--menu-link-color);
$menu-link-active-color: var(--menu-link-color-active);
$menu-background-color: var(--menu-background-color);
$body-background-color: var(--body-background-color);
$separator-color: var(--separator-color);
$link-inactive-color: var(--link-inactive-color);

$link-border-width: 2px;