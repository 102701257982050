@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100&display=swap');
@import '../CommonStyles/Variables/variables';
@import '../CommonStyles/Mixins/common';

body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0.1px;
  word-spacing: 1px;
  line-height: 22px;
  background-color: $menu-background-color;
}

#main-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  color: $main-font-color;
}

#main-background {
  background-color: $body-background-color;
}

#left-column {
  min-width: 180px;
  max-width: 180px;
  min-height: 100vh;
  padding: 0px 50px;
  background-color: $menu-background-color;
  box-shadow: 0px 0px 10px 0px rgba(58, 58, 58, 0.1);
}

#right-column {
  margin-top: 20px;
  width: 100%;
}

.main-content {
  padding: 22px;
  background-color: $body-background-color;
}

.navigation {
  font-size: 35px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 1px solid $separator-color;

  margin-bottom: 25px;
}

footer {
  font-size: 11px;
  color: rgb(214, 214, 214);
  text-align: center;
  margin-top: 50px;
}

a {
  @include link;
  text-decoration: none;

  padding-bottom: 2px;
  border-bottom: 1px solid $link-inactive-color;
  line-height: 27px;

  &:hover,
  &:active,
  &.selected {
    @include active-link
  }

  &.selected {
    font-weight: bold;
  }
}

.hamburger-menu {
  position: absolute;
  right: 25px;
  top: 20px;
  display: none;
}

hr {
  border: 0;
  border-top: 1px solid $separator-color;
}

.loader {
  color: gray;
  display: none;
}

@media only screen and (max-width: 1500px) {
  #right-column {
    min-width: 650px;
  }
}

@media only screen and (max-width: 1300px) {
  #main-container {
    width: 80%;
  }

  #right-column {
    min-width: 410px;
  }
}

@media only screen and (max-width: 830px) {
  #main-container {
    width: 100%;
  }

  #right-column {
    min-width: 200px;
    position: relative;
    min-height: 100vh;
    top: 40px;
    padding: 10px;

    &.burger-menu-opened {
      top: 230px;

      .navigation {
        position: absolute;
        top: -223px;
      }
    }
  }

  #left-column {
    visibility: hidden;
    min-width: 100%;
    max-width: 100%;
    position: absolute;
    z-index: 2;
    top: 70px;
    padding: 0px;

    .menu {
      margin: 0 5px;

      &.burger-menu-opened {
        background-color: $menu-background-color;
        visibility: visible;
        text-align: center;
        margin: 0px;
        padding: 10px;
      }
    }

    .change-theme {
      visibility: visible;
      position: fixed;
    }
  }

  .burger-menu-opened {
    .content {
      position: relative;
      top: 30px;
    }
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .navigation {
    position: absolute;
    top: -33px;
  }

  .avatar-container {
    visibility: visible;
    position: absolute;
    width: 40px !important;
    right: 78px;
    top: -128px;

    img {
      width: 35px !important;
    }
  }

  .social {
    &.burger-menu-opened {
      visibility: visible;
      position: relative;
      top: -20px;
      width: 100%;
      text-align: center;
    }

    hr {
      margin-top: 35px
    }
  }

  .github-stats {
    max-width: 100%;
    width: 500px;
  }
}