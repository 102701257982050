@import '../CommonStyles/Variables/variables';
@import '../CommonStyles/Mixins/common';

.menu {
  list-style-type: none;
  padding: 0;

  li {
    user-select: none;
    text-align: left;
  }

  a {
    color: $menu-link-color;
    text-decoration: none;
    display: block;
    border-bottom: $link-border-width solid $menu-background-color;
    margin: 6px 0px;
    padding-bottom: $link-border-width;
    position: relative;

    .menu-icon {
      color: $menu-link-color;
    }

    &.selected {
      font-weight: bold;
      @include active-link;
      border-bottom: $link-border-width solid $menu-link-active-color;

      .menu-icon {
        color: $menu-link-active-color;
      }
    }

    &:hover {
      color: $menu-link-active-color;

      &:after {
        width: 100%;
      }

      .menu-icon {
        color: $menu-link-active-color;
      }
    }

    // Set up the initial state of the border using a pseudo-element
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -$link-border-width;
      width: 0;
      height: $link-border-width;
      background: $menu-link-active-color;
      transition: width 0.3s ease;
    }
  }

  .menu-icon {
    position: absolute;
    top: 4px;
    font-size: 19px;
    margin-right: 10px;
    left: -30px;
  }
}

.change-theme {
  a {
    border-bottom: 0;
  }

  position: fixed;
  bottom: 20px;
  right: 20px;
}

.avatar-container {
  margin-top: 80px;
  user-select: none;
  text-align: center;

  a {
    border: none;
  }

  a:hover {
    border: 0;
  }

  img {
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.social {
  text-align: center;
  margin-top: 40px;

  a {
    border: none;
    padding: 1px;
  }
}