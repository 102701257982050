@import '../Variables/variables';

@mixin link {
    color: $link-color;
    font-weight: 600;
    border-bottom: $link-border-width solid $link-inactive-color;
}

@mixin active-link {
    color: $link-active-color;
    text-decoration: none;
    border-bottom: $link-border-width solid $link-active-color;
}